export default {
  common: {
    copy: '复制',
    copySuccessMessage: '复制成功',
    copyFailedMessage: '该浏览器不支持自动复制'
  },
  title: {
    homePage: 'MEPAY Explorer - Home Page',
    tokens: 'Token ecology',
    tokenDetail: 'Token details',
    boxredirect: 'Smart Contract Homepage',
    contractsAll:'Smart contracts',
    contractsOther:'Other contracts',
    blockList:'Block information',
    blockDetail:'Block details',
    transactionDetail:'Transaction details',
    account:'Account details',
    error:'No content',
    404:'404',
  },
  navbar: {
    homePage: 'ផ្ទះ',
    contracts: 'កិច្ចសន្យាឆ្លាត',
    tokens: 'ព័ត៌មាននិមិត្តសញ្ញា',
    blocks: 'រារាំងព័ត៌មាន',
    generateKey: '密钥生成'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: '技术支持',
    lab: 'xxx技术实验室',
    explorer: '区块链浏览器',
    wallet: 'Tải xuống ví',
    help: '帮助中心',
    about: 'về chúng tôi',
    group: 'xxx集团',
    support: 'Ủng hộ',
    cooperation: 'Hợp tác kinh doanh',
    community: 'xxx社区'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'
  },
  search: {
    inputPlaceholder: 'ស្វែងរក / រារាំង / គណនី/ Trx Hash',
    errorMessage: '错误：API错误：未知块：未知交易：未知帐户'
  },
  main: {
    producer: 'ប្លុកអ្នកផលិត',
    headBlock: 'កម្ពស់ប្លុក',
    tps: 'បច្ចុប្បន្ន/ខ្ពស់បំផុតTPS',
    txs: 'ចំនួនប្រតិបត្តិការ',
    transactions: 'កំណត់ត្រាប្រតិបត្តិការចុងក្រោយ',
    producers: 'ថ្នាំងទំនើប',
    applyJoin: 'អនុវត្តដើម្បីចូលរួមថ្នាំងទំនើប',
    rank: 'NO.',
    nodeArea: 'ប្រទេស/តំបន់',
    votes: 'ទំងន់/អត្រាបោះឆ្នោត',
    nodeName: 'ឈ្មោះ',
    blockId: 'ប្លុកចុងក្រោយ',
    applyJoinErrorMessage: '该功能暂未开放，敬请期待'
  },
  blocks: {
    blocks: 'បញ្ជីទប់ស្កាត់',
    block: 'រារាំង',
    blockId: 'រារាំងHash',
    txs: 'ចំនួនប្រតិបត្តិការ',
    actions: 'ល្ខោនអូប៉េរ៉ា',
    producer: 'ម៉ាស៊ីនភ្លើង',
    produceTime: 'សាងសង់ពេលវេលា'
  },
  blockDetail: {
    transactions: 'កំណត់ត្រាប្រតិបត្តិការ'
  },
  transactionDetail: {
    detail: 'ព័ត៌មានលម្អិតអំពីប្រតិបត្តិការ',
    actions: 'Actions',
    raw: 'ទិន្នន័យ​ដើម',
    txId: 'កំណត់ត្រាប្Hash',
    blockId: 'រារាំងHash',
    prevBlockId: 'ប្លុកមុនHash',
    headBlock: 'ប្លុក',
    producer: 'ប្លុកអ្នកផលិត',
    createTime: 'ត្រាពេលវេលា',
    action: 'ដំណើរការ​កម្រិត​ពណ៌',
    from: 'ផ្ទេរ',
    to: 'អ្នក​ទទួល',
    quantity: 'លេខ',
    memo: 'អត្ថបទ'
  },
  detail: {
    block: {
      blockDetail: 'ព័ត៌មានលម្អិតប្លុក',
      blockId: 'រារាំងHash',
      prevBlockId: 'ប្លុកមុនHash',
      headBlock: 'កម្ពស់ប្លុក',
      txs: 'ចំនួនកំណត់ត្រា',
      producer: 'ប្លុកអ្នកផលិត',
      createTime: 'ត្រាពេលវេលា'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: 'ពេលវេលា',
      action: 'Action',
      data: 'ទិន្នន័យ'
    }
  },
  account: {
    created: 'ពេលវេលា',
    resources: 'ទ្រព្យសម្បត្តិ',
    available: 'សមតុល្យដែលមាន',
    refunding: '赎回中',
    tokens: 'កាន់ថូខឹន',
    transactions: 'កំណត់ត្រាប្រតិបត្តិការ',
    detail: 'ព័ត៌មានលម្អិតអំពីគណនី',
    name: 'ឈ្មោះ',
    createdTime: 'ពេលវេលាបង្កើត',
    mainTokenBalance: 'សមតុល្យរូបិយប័ណ្ណចម្បង',
    totalAsset: '总价值',
    owner: 'Owner កូនសោសាធារណៈ',
    active: 'Active កូនសោសាធារណៈ'
  },
  404: {
    message: '请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告',
    goHome: '返回首页'
  },
  error: {
    message: 'MEPAY Explorer 支持搜索区块高度、区块Hash、账号、交易等链上信息，建议您确认输入内容是否正确后，尝试重新搜索。'
  },
  tokens: {
    tokensTitle: 'បរិស្សានវិទ្យា',
    update: 'បានធ្វើបច្ចុប្បន្នភាពនៅលើ',
    totalToken: 'បរិមាណថូខឹនបច្ចុប្បន្ន',
    searchToken: 'ស្វែងរក Token',
    name: 'ឈ្មោះ',
    issuerName: 'អ្នកចេញ',
    supply: 'ចលនាឈាមរត',
    maxSupply: 'ការចេញសរុប',
    toDetail: 'មើល',
    tokenDetails: 'ព័ត៌មានលំអិតថូខឹន',
    transactions: 'កំណត់ត្រាប្រតិបត្តិការចុងក្រោយ',
    tokenRank: 'ចំណាត់ថ្នាក់ថូខឹន',
    rank: '្នាក់ថូខឹន',
    account: 'ឈ្មោះ​អ្នកប្រើប្រាស់',
    balance: 'តុល្យភាព',
    percentage: 'ភាគរយ',
    tokenAccountsTotal: 'ចំនួនគណនីថូខឹនបច្ចុប្បន្ន',
    tokenAccountPercentageTitle: 'ការចែកចាយគណនីកាន់ថូខឹន'
  },
  contracts: {
    name: 'ឈ្មោះកិច្ចសន្យា',
    totalActiveAccountCount: '24h ចំនួនគណនីសកម្ម',
    totalCallCount: '24h ចំនួននៃការហៅទូរស័ព្ទ',
    actions: '合约方法',
    lastUpdateTime: 'កាលបរិច្ឆេទដាក់ពង្រាយចុងក្រោយ',
    ycxjyzt: 'ស្ថានភាពផ្ទៀងផ្ទាត់តែម្តង',
    dmsjzt: 'ស្ថានភាពសវនកម្មលេខកូដ'
  },
  actions: {
    issue: {
      operate: '系统派发了',
      to: '给',
      dailyissue: '每日发行'
    },
    activate: {
      operate: '邀请账户'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'ក្រុមហ៊ុនម៉ាយប៊ែលគឺជាកម្មវិធីចែកចាយចែកចាយអ្នកផលិតប្លុកនឹងត្រូវបានជ្រើសរើសដើម្បីផ្តល់នូវបរិយាកាសពាណិជ្ជកម្មលឿននិងមានស្ថេរភាពសម្រាប់សហគមន៍។ អ្នកផលិតប្លុកនឹងត្រូវបានភ្ជាប់ទៅនឹងបណ្តាញចែកចាយដែលមានទម្រង់ខ្ពស់ដើម្បីផ្តល់នូវប្រតិបត្តិការធម្មតានៃបណ្តាញហើយអ្នកនឹងស្ថិតនៅលើ MEPAY ។ អ្នកអាចសរសេរកូដដើម្បីដំណើរការកិច្ចសន្យាឆ្លាតរបស់អ្នក'
  },
  newfooter: {
    title1: 'MEPAY សហគមន៍',
    title2: 'ការ​គាំទ្រ​ផ្នែក​បច្ចេកទេស',
    title3: 'អំពី​ពួក​យើង',
    text1: 'កូដប្រភព blockchain',
    text2: 'ទាញយកកាបូប',
    text3: 'គាំទ្រ',
    text4: 'កិច្ចសហប្រតិបត្តិការអាជីវកម្ម'
  },
  chaoji: {
    title1: '编号',
    title2: '节点区域',
    title3: '节点ID',
    title4: '节点名称',
    title5: '区块高度',
    title6: '最新区块Hash',
    zanwu: '暂无数据'
  },
  yiyuan: {
    title1: '愿景',
    text1: '客户第一安全第一',
    title2: '使命',
    title3: '价值观'
  },
  zizhanghao: {
    text1: 'Sub account',
    text2: 'Creation time'
  }
}
