export default {
    common: {
        copy: 'Copie',
        copySuccessMessage: 'Copie réussie',
        copyFailedMessage: 'Le navigateur ne prend pas en charge la copie automatique'
    },
    title: {
        homePage: 'MEPAY Explorer - Home Page',
        tokens: 'Token ecology',
        tokenDetail: 'Token details',
        boxredirect: 'Smart Contract Homepage',
        contractsAll:'Smart contracts',
        contractsOther:'Other contracts',
        blockList:'Block information',
        blockDetail:'Block details',
        transactionDetail:'Transaction details',
        account:'Account details',
        error:'No content',
        404:'404',
    },
    navbar: {
        homePage: 'Accueil',
        contracts: 'contrat smart',
        tokens: 'Information monétaire',
        blocks: 'Information de bloc',
        generateKey: 'Génération de clés'
    },
    footer: {
        ecological: 'xxx',
        ex: 'xxx',
        game: 'xxx',
        sto: 'xxx',
        bank: 'xxx',
        capital: 'xxx',
        techSupport: 'Soutien technique',
        lab: 'xxxLaboratoire technologique',
        explorer: 'Navigateur Blockchain',
        wallet: 'Porte-monnaie télécharger',
        help: "Centre d'aide",
        about: 'à propos de nous',
        group: 'xxxgroupe',
        support: 'Aide au support',
        cooperation: "coopération d'affaires",
        community: 'xxx communauté'
    },
    lang: {
        en: 'English',
        cn: '简体中文',
        ko: '한국어',
        tw: '中文繁体',
        ja: '日本語',
        pt: 'Português',
        ru: 'русский',
        es: 'Español',
        fr: 'français',
        de: 'Deutsche',
        ar: 'عربى'
    },
    search: {
        inputPlaceholder: 'Search Block/ compte / Trx Hash',
        errorMessage: 'Erreur: erreur API: bloc inconnu: transaction inconnue: compte inconnu'
    },
    main: {
        producer: 'Nœud de bloc',
        headBlock: 'hauteur bloc',
        tps: 'Actuels TPS/maximales',
        txs: 'Nombre de transactions',
        transactions: 'Dernier historique des transactions',
        producers: 'Supernode',
        applyJoin: 'Postuler pour rejoindre le super nœud',
        rank: 'Rang',
        nodeArea: 'pays/région',
        votes: 'Poids/Taux de votes',
        nodeName: 'Nom',
        blockId: 'Dernier bloc Hash',
        applyJoinErrorMessage: "Cette fonctionnalité n'est pas encore ouverte, alors restez à l'écoute"
    },
    blocks: {
        blocks: 'Liste de blocage',
        block: 'Bloquer',
        blockId: 'Hash bloc',
        txs: 'Valeur transactionnelle',
        actions: 'Opérande',
        producer: 'Générateur',
        produceTime: 'Temps de génération'
    },
    blockDetail: {
        transactions: "Relevé d'opération"
    },
    transactionDetail: {
        detail: 'détails de la transaction',
        actions: 'Actions',
        raw: 'Données brutes',
        txId: 'Trading Hash',
        blockId: 'Hash bloc',
        prevBlockId: 'Bloc précédent Hash',
        headBlock: 'Bloquer',
        producer: 'noeud bloc',
        createTime: 'Horodatage',
        action: 'Méthode de contrat',
        from: 'Cédant',
        to: 'Destinataire',
        quantity: 'Quantité',
        memo: 'Remarques'
    },
    detail: {
        block: {
            blockDetail: 'détails bloc',
            blockId: 'Hash bloc',
            prevBlockId: 'Hash Bloc précédent',
            headBlock: 'hauteur bloc',
            txs: "Nombre d'enregistrements",
            producer: 'Nœud de bloc',
            createTime: 'Horodatage'
        },
        transaction: {
            trxId: 'Trx Hash',
            time: 'temps',
            action: 'Action',
            data: 'données'
        }
    },
    account: {
        created: 'Construit en',
        resources: 'les atouts',
        available: 'Solde disponible',
        refunding: 'Échange',
        tokens: 'Tenir des jetons',
        transactions: "Relevé d'opération",
        detail: 'Détails du compte',
        name: 'Nom',
        createdTime: 'Temps de creation',
        mainTokenBalance: 'Solde de la devise principale',
        totalAsset: 'Valeur totale',
        owner: 'Owner Clé publique',
        active: 'Active Clé publique'
    },
    404: {
        message: "Veuillez vérifier si l'URL que vous avez saisie est correcte, veuillez cliquer sur le bouton ci-dessous pour revenir à la page d'accueil ou envoyer un rapport d'erreur",
        goHome: 'Retour au sommet'
    },
    error: {
        message: "NGK Explorer prend en charge la recherche d'informations sur la chaîne, telles que la hauteur de bloc, le hachage de bloc, le numéro de compte et les transactions. Il est recommandé de réessayer après avoir vérifié que le contenu saisi est correct."
    },
    tokens: {
        tokensTitle: 'écologie Token',
        update: 'Mis à jour le',
        totalToken: 'actuel Token Quantité',
        searchToken: 'Recherche Token',
        name: 'Token Nom',
        issuerName: 'Émetteur',
        supply: 'Liquidité',
        maxSupply: 'Émission totale',
        toDetail: 'Vue',
        tokenDetails: 'Token Détails',
        transactions: "Relevé d'opération",
        tokenRank: 'Token ',
        rank: 'Rang',
        account: "Nom d'utilisateur",
        balance: 'Équilibre',
        percentage: 'Proportion',
        tokenAccountsTotal: 'Nombre actuel de comptes de dépôt jeton',
        tokenAccountPercentageTitle: 'Répartition des comptes de dépôt jeton'
    },
    contracts: {
        name: 'Nom du contrat',
        totalActiveAccountCount: '24h Comptes actifs',
        totalCallCount: '24h Appels',
        actions: 'Méthode de contrat',
        lastUpdateTime: 'Dernière date de déploiement',
        ycxjyzt: 'état de contrôle unique',
        dmsjzt: "Statut d'audit du code"
    },
    actions: {
        issue: {
            operate: 'Le système distribué',
            to: 'donner',
            dailyissue: 'Numéro quotidien'
        },
        activate: {
            operate: "Compte d'invitation"
        }
    },
    banner: {
        title: 'MEPAY ',
        titleen: 'Blockchain Explorer',
        text: "NGK.IO est un programme d'application distribué.Les producteurs de blocs seront sélectionnés pour fournir un environnement commercial rapide et stable à la communauté.Les producteurs de blocs auront accès à des serveurs distribués haut de gamme pour assurer les opérations normales du réseau.Vous pouvez écrire du code sur .IO pour exécuter votre propre contrat intelligent!"
    },
    newfooter: {
        title1: 'Communauté NGK',
        title2: 'Soutien technique',
        title3: 'à propos de nous',
        text1: 'code source Blockchain',
        text2: 'Porte-monnaie télécharger',
        text3: 'Aide au support:',
        text4: 'Coopération entreprise:'
    },
    chaoji: {
        title1: '编号',
        title2: '节点区域',
        title3: '节点ID',
        title4: '节点名称',
        title5: '区块高度',
        title6: '最新区块Hash',
        zanwu: '暂无数据'
    }
}

