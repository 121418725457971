export default {
    common: {
        copy: 'копия',
        copySuccessMessage: 'Копирование успешно',
        copyFailedMessage: 'Браузер не поддерживает автоматическое копирование'
    },
    title: {
        homePage: 'MEPAY Explorer - Home Page',
        tokens: 'Token ecology',
        tokenDetail: 'Token details',
        boxredirect: 'Smart Contract Homepage',
        contractsAll:'Smart contracts',
        contractsOther:'Other contracts',
        blockList:'Block information',
        blockDetail:'Block details',
        transactionDetail:'Transaction details',
        account:'Account details',
        error:'No content',
        404:'404',
    },
    navbar: {
        homePage: 'Домой',
        contracts: 'Умный контракт',
        tokens: 'Информация о токене',
        blocks: 'Блок информации',
        generateKey: 'Генерация ключей'
    },
    footer: {
        ecological: 'xxx',
        ex: 'xxx',
        game: 'xxx',
        sto: 'xxx',
        bank: 'xxx',
        capital: 'xxx',
        techSupport: 'Техническая поддержка',
        lab: 'xxxТехнологическая лаборатория',
        explorer: 'Блокчейн браузер',
        wallet: 'Скачать кошелек',
        help: 'Центр помощи',
        about: 'насчет нас',
        group: 'xxxгруппа',
        support: 'Поддержка помощь',
        cooperation: 'Деловое сотрудничество',
        community: 'xxxсообщество'
    },
    lang: {
        en: 'English',
        cn: '简体中文',
        ko: '한국어',
        tw: '中文繁体',
        ja: '日本語',
        pt: 'Português',
        ru: 'русский',
        es: 'Español',
        fr: 'français',
        de: 'Deutsche',
        ar: 'عربى'
    },
    search: {
        inputPlaceholder: 'Поиск Block / Account / Trx Hash',
        errorMessage: 'Ошибка: Ошибка API: Неизвестный Блок: Неизвестная транзакция: Неизвестная учетная запись'
    },
    main: {
        producer: 'Блочный узел',
        headBlock: 'Высота блока',
        tps: 'Текущие/высокие TPS',
        txs: 'Количество сделок',
        transactions: 'Последняя история транзакций',
        producers: 'Supernode',
        applyJoin: 'Подать заявку на присоединение к суперузлу',
        rank: 'Ранг',
        nodeArea: 'страна / регион',
        votes: 'Вес / Рейтинг голосов',
        nodeName: 'название',
        blockId: 'Последний блок Hash',
        applyJoinErrorMessage: 'Эта функция еще не открыта, так что следите за обновлениями'
    },
    blocks: {
        blocks: 'Черный список',
        block: 'блок',
        blockId: 'Блок Hash',
        txs: 'Количество сделок',
        actions: 'Операнд',
        producer: 'Генератор',
        produceTime: 'Время генерации'
    },
    blockDetail: {
        transactions: 'Запись транзакции'
    },
    transactionDetail: {
        detail: 'Детали транзакции',
        actions: 'Actions',
        raw: 'Исходные данные',
        txId: 'Торговый Hash',
        blockId: 'Блок Hash',
        prevBlockId: 'Предыдущий Hash блок',
        headBlock: 'блок',
        producer: 'Блочный узел',
        createTime: 'Отметка',
        action: 'метод контракта',
        from: 'цедент',
        to: 'Приемник',
        quantity: 'Количество',
        memo: 'замечания'
    },
    detail: {
        block: {
            blockDetail: 'Детали блока',
            blockId: 'Блок Hash',
            prevBlockId: 'Предыдущий блок Hash',
            headBlock: 'Высота блока',
            txs: 'Количество записей',
            producer: 'Блочный узел',
            createTime: 'Отметка'
        },
        transaction: {
            trxId: 'Trx Hash',
            time: 'время',
            action: 'Action',
            data: 'данные'
        }
    },
    account: {
        created: 'Встроенный',
        resources: 'активы',
        available: 'Доступный баланс',
        refunding: 'искупающий',
        tokens: 'Держа жетоны',
        transactions: 'Транзакция записи',
        detail: 'Данные учетной записи',
        name: 'название',
        createdTime: 'Время создания',
        mainTokenBalance: 'Главная валюта баланса',
        totalAsset: 'Общая стоимость',
        owner: 'Owner Открытый ключ',
        active: 'Active Открытый ключ'
    },
    404: {
        message: 'Пожалуйста, проверьте правильность введенного вами URL. Нажмите кнопку ниже, чтобы вернуться на главную страницу или отправить отчет об ошибке.',
        goHome: 'Вернуться к началу'
    },
    error: {
        message: 'NGK Explorer Он поддерживает поиск информации в цепочке, такой как высота блока, хэш блока, номер счета и транзакция. Рекомендуется повторить поиск после подтверждения правильности введенного содержимого.'
    },
    tokens: {
        tokensTitle: 'Токен экология',
        update: 'Обновление',
        totalToken: 'Текущее количество токенов',
        searchToken: 'Поиск токена',
        name: 'Имя токена',
        issuerName: 'эмитент',
        supply: 'ликвидности',
        maxSupply: 'Общий выпуск',
        toDetail: 'вид',
        tokenDetails: 'Детали токена',
        transactions: 'Запись транзакции',
        tokenRank: 'Token Ранг',
        rank: 'Ранг',
        account: 'имя пользователя',
        balance: 'Баланс',
        percentage: 'доля',
        tokenAccountsTotal: 'Текущее количество токенов на счетах',
        tokenAccountPercentageTitle: 'Распределение токенов на счетах'
    },
    contracts: {
        name: 'Название контракта',
        totalActiveAccountCount: '24h Активные счета',
        totalCallCount: '24h Вызовы',
        actions: 'метод контракта',
        lastUpdateTime: 'Последняя дата развертывания',
        ycxjyzt: 'Статус проверки Одноразовый',
        dmsjzt: 'Код аудита статуса'
    },
    actions: {
        issue: {
            operate: 'Система распределена',
            to: 'дайте',
            dailyissue: 'Ежедневный выпуск'
        },
        activate: {
            operate: 'Приглашение счета'
        }
    },
    banner: {
        title: 'MEPAY ',
        titleen: 'Blockchain Explorer',
        text: 'NGK.IO - это распределенное приложение. Производители блоков будут выбраны, чтобы обеспечить быструю и стабильную торговую среду для сообщества. Производители блоков будут получать доступ к распределенным серверам высокого класса для обеспечения нормальной работы сети. Вы можете написать код на .IO, чтобы запустить свой собственный умный контракт!'
    },
    newfooter: {
        title1: 'Сообщество NGK',
        title2: 'Техподдержка',
        title3: 'насчет нас',
        text1: 'Blockchain исходный код',
        text2: 'Скачать кошелек',
        text3: 'Поддержка справки:',
        text4: 'Деловое сотрудничество:'
    },
    chaoji: {
        title1: '',
        title2: '节点区域',
        title3: '节点ID',
        title4: '节点名称',
        title5: '区块高度',
        title6: '最新区块Hash',
        zanwu: '暂无数据'
    }
}

