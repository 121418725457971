<template>
  <div class="page-header">
    <div class="w header-loc">
      <div class="app-logo" style="cursor: pointer;" @click="goHome()">
        <img src="@/assets/static/ngklogo.png" class="logo" />
        <img src="@/assets/static/ngklogo.png" class="logo1" />
        <!-- <em class="app-logo-txt">NGK Explorer</em> -->
      </div>
      <div class="navrightBox">
        <div class="header-menu" v-if="showDropdown">
          <ul class="nav">
            <li @click="jumpTo">
              <router-link to="/" exact>{{ $t('navbar.homePage') }}</router-link>
            </li>
            <li @click="jumpTo">
              <router-link to="/blocks">{{ $t('navbar.blocks') }}</router-link>
            </li>
            <li @click="jumpTo">
              <router-link to="/tokens">{{ $t('navbar.tokens') }}</router-link>
            </li>
            <li @click="jumpTo">
              <router-link to="/contracts">{{ $t('navbar.contracts') }}</router-link>
            </li>
          </ul>
        </div>
        <div class="header-sidebar" v-show="showDropdown">
          <div class="sidebar-item lang">
            <span class="sub-dropdown-text" @click="toggleLang">
              <i class="icon-lang" :class="'icon-lang-'+langConfig[langIndex].key"></i>
              <em>{{langConfig[langIndex].value}}</em>
              <b style="color:#00C4F4;"><el-icon :size="16"><ArrowDownBold /></el-icon></b>
            </span>
            <div class="sub-dropdown-list" v-show="showLang">
              <div
                class="sub-dropdown-item"
                :class="{on:index == langIndex}"
                v-for="(item, index) in langConfig"
                :key="index+1"
                @click="selectLang(index)"
              >
                <i class="icon-lang" :class="'icon-lang-'+item.key"></i>
                {{item.value}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="tools-nav isMobile">
        <i class="iconfont iconmenu" v-if="!showDropdown" @click="openDropDown"></i>
        <i class="iconfont iconclose header-tools-close" v-if="showDropdown" @click="closeDropDown"></i>
      </span>
    </div>
  </div>
</template>

<script>

import {createI18n} from "vue-i18n";
import Cookies from "js-cookie";
import messages from "../../lang";

export default {
  data() {
    return {
      dialogMenuVisible: true,
      nodeRadio: "1",
      isLogin: false,
      nickName: "",
      getwidth: 0,
      showDropdown: this._isMobile() ? false : true,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      showLang: false,
      langConfig: [
        { value: this.$t("lang.tw"), key: "TW", lang: "zh-tw" },
        { value: this.$t("lang.cn"), key: "CN", lang: "zh-cn" },
        { value: this.$t("lang.en"), key: "US", lang: "en" },
        { value: this.$t("lang.ko"), key: "KO", lang: "ko" },
        { value: this.$t("lang.ja"), key: "JA", lang: "ja" },
        { value: this.$t("lang.kh"), key: "KH", lang: "kh" },
        { value: this.$t("lang.th"), key: "TH", lang: "th" },
        { value: this.$t("lang.vn"), key: "VN", lang: "vn" }
      ],
      languageText: "",
      language:''
    };
  },
  computed: {
    lang() {
      return this.$store.state.app.language;
    },
    langIndex: {
      get() {
        return this.$store.state.app.langIndex;
      },
      set(index) {
        this.$i18n.locale = this.langConfig[index].lang;
        this.$store.dispatch("setLangIndex", index);
        this.$store.dispatch("setLanguage", this.langConfig[index].lang);
      }
    }
  },
  watch: {
    langIndex() {
      this.setLangConfig();
      document.title =  this.$t(this.$route.meta.title)
    },
    windowWidth(val) {
      let that = this;
      var winWidth;
      if (window.innerWidth) {
        winWidth = window.innerWidth;
      } else if (document.body && document.body.clientWidth) {
        winWidth = document.body.clientWidth;
      }
      this.getwidth = winWidth;
      if (winWidth > 1200) {
        this.showDropdown = true;
        // console.log(this.showDropdown);
      }
      if (winWidth < 1200) {
        this.showDropdown = true;
      }
    },

  },
  created() {
    var winWidth;
    if (window.innerWidth) {
      winWidth = window.innerWidth;
    } else if (document.body && document.body.clientWidth) {
      winWidth = document.body.clientWidth;
    }
    if (winWidth < 1200) {
    }



  },
  mounted() {
    const that = this;
    window.addEventListener("resize", function() {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.windowWidth = window.screenWidth;
      })();
    });

  },
  methods: {
    sendMsg() {
      //func: 是父组件指定的传数据绑定的函数，this.msg:子组件给父组件传递的数据
      this.$emit("func", this.languageText);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    setLangConfig() {
      this.langConfig = [
        { value: this.$t("lang.tw"), key: "TW", lang: "zh-tw" },
        { value: this.$t("lang.cn"), key: "CN", lang: "zh-cn" },
        { value: this.$t("lang.en"), key: "US", lang: "en" },
        { value: this.$t("lang.ko"), key: "KO", lang: "ko" },
        { value: this.$t("lang.ja"), key: "JA", lang: "ja" },
        { value: this.$t("lang.kh"), key: "KH", lang: "kh" },
        { value: this.$t("lang.th"), key: "TH", lang: "th" },
        { value: this.$t("lang.vn"), key: "VN", lang: "vn" }
      ];
    },
    toggleLang() {
      this.showLang = !this.showLang;
    },
    selectLang(index) {
      // console.log(index);
      this.langIndex = index;
      this.showLang = false;
      this.showDropdown = true;
      // console.log(
      //   this.$store.state.app.language,
      //   "this.$store.state.app.language"
      // );
      this.languageText = this.$store.state.app.language;
      this.sendMsg();
    },
    handleLogin() {
      this.isLogin = true;
      this.nickName = "wangdoubi";
    },
    logout() {
      this.isLogin = false;
      this.nickName = "";
    },
    openDropDown() {
      this.showDropdown = true;
    },
    closeDropDown() {
      // console.log(this.getwidth);
      if (this.getwidth < 1200) {
        // console.log(this.getwidth);
        this.showDropdown = false;
      } else if (this.getwidth > 1200) {
        // console.log(this.getwidth);
        this.showDropdown = true;
      }
    },
    jumpTo() {
      if (!this._isMobile())
        return;
      this.showDropdown = false;
    },
    goHome() {
      this.$router.push({ path: "/" });
    }
  },
};
</script>

<style scoped lang="less">
@media (max-width: 768px) {
  .header-sidebar {
    position: fixed;
    top: 234px;
    left: calc(~"20% + 20px");
    z-index: 100;

    .lang {
      .sub-dropdown-text {
        color: #fff;
      }
      .sub-dropdown-list {
        color: #fff;
        font-size: 13px;
      }
    }
  }
}
</style>
