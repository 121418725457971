import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'


const Search = () => import('../views/search');
const Main = () => import('../views/main')


/*import {i18n} from "../lang/i18n"*/
import store from '../store';
import {showNotify} from "vant";

import Cookies from "js-cookie";
import { createI18n } from 'vue-i18n'
import messages from '../lang/index'



const routes = [
  {
    path: '/',
    component: Search,
    name: 'homepage',
    meta: {
      title: 'title.homePage'
     // title: 'MEPAY Explorer'
    },
    hidden: true,


    children: [
      {
        path: "/",
        component: Main,
      },
      {
        path: '/tokens',
        component: () => import('@/views/tokens'),
        name: 'tokens',
        meta: {
         // title: 'Token生态'
          title: 'title.tokens'
        },
        hidden: true
      },
      {
        path: '/tokens/:id',
        component: () => import('@/views/tokenDetail'),
        name: 'tokenDetail',
        meta: {
         // title: 'Token详情'
          title: 'title.tokenDetail'
        },
        hidden: true
      },
      {
        path: '/contracts',
        name: 'boxredirect',
        component: () => import('@/views/contracts'),
        meta: {
          title: 'title.boxredirect'
        //  title: '智能合约首页'
        },
        hidden: true
      },
      /*{
        path: '/contracts/all',
        component: () => import('@/views/contracts'),
        name: 'contractsAll',
        meta: {
       //   title: '智能合约'
          title: 'title.contractsAll'
        },
        hidden: true
      },*/
      {
        path: '/contracts/other',
        component: () => import('@/views/contracts'),
        name: 'contractsOther',
        meta: {
         // title: '其它合约'
          title: 'title.contractsOther'
        },
        hidden: true
      },
      {
        path: '/blocks',
        component: () => import('@/views/blocks'),
        name: 'blockList',
        meta: {
        //  title: '区块信息'
          title: 'title.blockList'
        },
        hidden: true
      },
      {
        path: '/block/:id',
        component: () => import('@/views/blockDetail'),
        name: 'blockDetail',
        meta: {
       //   title: '区块详情'
          title: 'title.blockDetail'
        },
        hidden: true
      },
      {
        path: '/transaction/:id',
        component: () => import('@/views/transactionDetail'),
        name: 'transactionDetail',
        meta: {
      //    title: '交易详情'
          title: 'title.transactionDetail'
        },
        hidden: true
      },
      {
        path: '/account/:id',
        component: () => import('@/views/account'),
        name: 'account',
        meta: {
       //   title: '账号详情'
          title: 'title.account'
        },
        hidden: true
      },
      {
        path: 'error',
        component: () => import('@/views/error'),
        name: 'error',
        meta: {
          title: 'title.error'
        //  title: '无内容'
        },
        hidden: true
      }
    ],

  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    name: '404',
    meta: {
     // title: '404'
      title: 'title.404'
    },
    hidden: true
  },
  { path:"/:pathMatch(.*)", redirect: '/404', hidden: true }
]

const router = createRouter({
   // history: createWebHistory(process.env.BASE_URL),
    history:createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {

  if (to.meta && to.meta.title) {
    const  i18n = createI18n({
      legacy: false,
      ignoreRoutes: false,
      locale: Cookies.get('language') || 'zh-tw',
      messages
    })
    document.title = i18n.global.t(to.meta.title);
  }
  next();


})

export default router
