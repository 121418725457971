export default {
  common: {
    copy: '复制',
    copySuccessMessage: '复制成功',
    copyFailedMessage: '该浏览器不支持自动复制'
  },
  title: {
    homePage: 'MEPAY Explorer - Home Page',
    tokens: 'Token ecology',
    tokenDetail: 'Token details',
    boxredirect: 'Smart Contract Homepage',
    contractsAll:'Smart contracts',
    contractsOther:'Other contracts',
    blockList:'Block information',
    blockDetail:'Block details',
    transactionDetail:'Transaction details',
    account:'Account details',
    error:'No content',
    404:'404',
  },
  navbar: {
    homePage: 'บ้าน',
    contracts: 'สัญญาอัจฉริยะ',
    tokens: 'ข้อมูลโทเค็น',
    blocks: 'บล็อกข้อมูล',
    generateKey: '密钥生成'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: 'การสนับสนุนทางเทคนิค',
    lab: 'xxx技术实验室',
    explorer: '区块链浏览器',
    wallet: 'ดาวน์โหลด Wallet',
    help: '帮助中心',
    about: 'เกี่ยวกับเรา',
    group: 'xxx集团',
    support: 'สนับสนุน',
    cooperation: 'ความร่วมมือทางธุรกิจ',
    community: 'xxx社区'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'
  },
  search: {
    inputPlaceholder: 'ค้นหา / บล็อก / บัญชี / Trx Hash',
    errorMessage: '错误：API错误：未知块：未知交易：未知帐户'
  },
  main: {
    producer: 'ผู้ผลิตบล็อก',
    headBlock: 'ความสูงของบล็อก',
    tps: 'ปัจจุบัน/สูงสุดTPS',
    txs: 'จำนวนธุรกรรม',
    transactions: 'บันทึกธุรกรรมล่าสุด',
    producers: 'ซุปเปอร์โหนด',
    applyJoin: 'สมัครเข้าร่วมซุปเปอร์โหนด',
    rank: '排名',
    nodeArea: 'ประเทศ/ภูมิภาค',
    votes: 'น้ำหนัก/อัตราการโหวต',
    nodeName: 'ชื่อ',
    blockId: 'บล็อกล่าสุดHash',
    applyJoinErrorMessage: 'ฟีเจอร์นี้ยังไม่เปิดดังนั้นโปรดอดใจรอ'
  },
  blocks: {
    blocks: 'รายการบล็อก',
    block: 'บล็อก',
    blockId: 'บล็อกHash',
    txs: 'จำนวนธุรกรรม',
    actions: 'ตัวดำเนินการ',
    producer: 'เครื่องกำเนิดไฟฟ้า',
    produceTime: 'สร้างเวลา'
  },
  blockDetail: {
    transactions: 'บันทึกธุรกรรม'
  },
  transactionDetail: {
    detail: 'บล็อก',
    actions: 'Actions',
    raw: 'ข้อมูลดิบ',
    txId: 'ธุรกรรมHash',
    blockId: 'บล็อกHash',
    prevBlockId: 'บล็อกก่อนหน้าHash',
    headBlock: 'บล็อก',
    producer: 'ผู้ผลิตบล็อก',
    createTime: 'การประทับเวลา',
    action: 'วิธีการทำสัญญา',
    from: 'คนโอน',
    to: 'ผู้รับ',
    quantity: 'ปริมาณ',
    memo: 'หมายเหตุ'
  },
  detail: {
    block: {
      blockDetail: 'รายละเอียดการบล็อก',
      blockId: 'บล็อกHash',
      prevBlockId: 'บล็อกก่อนหน้าHash',
      headBlock: 'ความสูงของบล็อก',
      txs: 'จำนวนบันทึก',
      producer: 'ผู้ผลิตบล็อก',
      createTime: 'การประทับเวลา'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: 'เวลา',
      action: 'Action',
      data: 'ข้อมูล'
    }
  },
  account: {
    created: 'สร้างขึ้นใน',
    resources: 'สินทรัพย์',
    available: 'ยอดเงินคงเหลือ',
    refunding: 'การไถ่ถอน',
    tokens: 'ถือโทเค็น',
    transactions: 'บันทึกธุรกรรม',
    detail: 'รายละเอียดบัญชี',
    name: 'ชื่อผู้ใช้',
    createdTime: 'เวลาสร้าง',
    mainTokenBalance: 'ดุลสกุลเงินหลัก',
    totalAsset: 'มูลค่ารวม',
    owner: 'Owner คีย์สาธารณะ',
    active: 'Active คีย์สาธารณะ'
  },
  404: {
    message: 'โปรดตรวจสอบว่า URL ที่คุณป้อนถูกต้องหรือไม่โปรดคลิกปุ่มด้านล่างเพื่อกลับไปที่หน้าแรกหรือส่งรายงานข้อผิดพลาด',
    goHome: 'กลับไปที่หน้าแรก'
  },
  error: {
    message: 'MEPAY Explorer รองรับการค้นหาความสูงของบล็อกแฮชบล็อกหมายเลขบัญชีธุรกรรมและข้อมูลอื่น ๆ บนเครือข่ายขอแนะนำให้คุณยืนยันว่าเนื้อหาที่ป้อนนั้นถูกต้องหรือไม่และลองค้นหาอีกครั้ง'
  },
  tokens: {
    tokensTitle: 'Tokenนิเวศวิทยา',
    update: 'อัปเดตเมื่อ',
    totalToken: 'ปริมาณโทเค็นปัจจุบัน',
    searchToken: 'ค้นหาโทเค็น',
    name: 'ชื่อโทเค็น',
    issuerName: 'ผู้ออก',
    supply: 'การไหลเวียน',
    maxSupply: 'การออกทั้งหมด',
    toDetail: 'ดู',
    tokenDetails: 'รายละเอียดโทเค็น',
    transactions: 'บันทึกธุรกรรม',
    tokenRank: 'อันดับโทเค็น',
    rank: 'อันดับ',
    account: 'ชื่อผู้ใช้',
    balance: 'สมดุล',
    percentage: 'เปอร์เซ็นต์',
    tokenAccountsTotal: 'จำนวนบัญชีโทเค็นปัจจุบัน',
    tokenAccountPercentageTitle: 'การกระจายบัญชีที่ถือโทเค็น'
  },
  contracts: {
    name: 'ชื่อสัญญา',
    totalActiveAccountCount: '24h จำนวนบัญชีที่ใช้งาน',
    totalCallCount: '24h จำนวนการโทร',
    actions: 'วิธีการทำสัญญา',
    lastUpdateTime: 'วันที่ปรับใช้ล่าสุด',
    ycxjyzt: 'สถานะการยืนยันเพียงครั้งเดียว',
    dmsjzt: 'สถานะการตรวจสอบรหัส'
  },
  actions: {
    issue: {
      operate: '系统派发了',
      to: '给',
      dailyissue: '每日发行'
    },
    activate: {
      operate: '邀请账户'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'MEPAY เป็นโปรแกรมแอปพลิเคชันแบบกระจายผู้ผลิตบล็อกจะถูกเลือกเพื่อให้มีสภาพแวดล้อมการซื้อขายที่รวดเร็วและมีเสถียรภาพสำหรับชุมชนผู้ผลิตบล็อกจะเชื่อมต่อกับเซิร์ฟเวอร์แบบกระจายรายละเอียดสูงเพื่อให้การทำงานของเครือข่ายเป็นไปตามปกติและคุณจะอยู่ใน MEPAY คุณสามารถเขียนโค้ดเพื่อเรียกใช้สัญญาอัจฉริยะของคุณเอง'
  },
  newfooter: {
    title1: 'MEPAY ชุมชน',
    title2: 'การสนับสนุนทางเทคนิค',
    title3: 'เกี่ยวกับเรา',
    text1: 'ซอร์สโค้ด Blockchain',
    text2: 'ดาวน์โหลด Wallet',
    text3: 'สนับสนุน',
    text4: 'ความร่วมมือทางธุรกิจ'
  },
  chaoji: {
    title1: 'จำนวน',
    title2: 'พื้นที่โหนด',
    title3: 'รหัสโหนด',
    title4: 'ชื่อโหนด',
    title5: 'ความสูงของบล็อก',
    title6: 'แฮชบล็อกล่าสุด',
    zanwu: 'ไม่มีข้อมูล'
  },
  yiyuan: {
    title1: '愿景',
    text1: '客户第一安全第一',
    title2: '使命',
    title3: '价值观'
  },
  zizhanghao: {
    text1: 'บัญชีย่อย',
    text2: 'เวลาสร้าง'
  }
}
