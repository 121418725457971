export default {
  common: {
    copy: '複製',
    copySuccessMessage: '複製成功',
    copyFailedMessage: '該瀏覽器不支持自動複製'
  },
  title: {
    homePage: 'MEPAY Explorer -首頁',
    tokens: 'Token生態',
    tokenDetail: 'Token詳情',
    boxredirect: '智慧合約首頁',
    contractsAll:'智慧合約',
    contractsOther:'其它合約',
    blockList:'區塊資訊',
    blockDetail:'區塊詳情',
    transactionDetail:'交易詳情',
    account:'帳號詳情',
    error:'無內容',
    404:'404',
  },

  navbar: {
    homePage: '首頁',
    contracts: '智能合約',
    tokens: '代幣信息',
    blocks: '區塊信息',
    generateKey: '密鑰生成'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: '技術支持',
    lab: 'xxx技術實驗室',
    explorer: '區塊鏈瀏覽器',
    wallet: '錢包下載',
    help: '幫助中心',
    about: '關於我們',
    group: 'xxx集團',
    support: '支持幫助',
    cooperation: '商務合作',
    community: 'xxx社區'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'
  },
  search: {
    inputPlaceholder: '搜索 區塊 / 賬戶 / Trx Hash',
    errorMessage: '錯誤：API錯誤：未知塊：未知交易：未知帳戶'
  },
  main: {
    producer: '出塊節點',
    headBlock: '區塊高度',
    tps: '當前/最高TPS',
    txs: '交易數量',
    transactions: '最新交易記錄',
    producers: '超級節點',
    applyJoin: '申請加入超級節點',
    rank: '排名',
    nodeArea: '國家地區',
    votes: '權重 / 得票率',
    nodeName: '名稱',
    blockId: '最新區塊Hash',
    applyJoinErrorMessage: '該功能暫未開放，敬請期待'
  },
  blocks: {
    blocks: '區塊列表',
    block: '區塊',
    blockId: '區塊Hash',
    txs: '交易數',
    actions: '操作數',
    producer: '生成者',
    produceTime: '生成時間'
  },
  blockDetail: {
    transactions: '交易記錄'
  },
  transactionDetail: {
    detail: '交易詳情',
    actions: 'Actions',
    raw: '原始數據',
    txId: '交易Hash',
    blockId: '區塊Hash',
    prevBlockId: '上一區塊Hash',
    headBlock: '所在區塊',
    producer: '出塊節點',
    createTime: '時間戳',
    action: '合約方法',
    from: '轉賬人',
    to: '接收人',
    quantity: '數量',
    memo: '備註'
  },
  detail: {
    block: {
      blockDetail: '區塊詳情',
      blockId: '區塊Hash',
      prevBlockId: '上一區塊Hash',
      headBlock: '區塊高度',
      txs: '記錄數量',
      producer: '出塊節點',
      createTime: '時間戳'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: '時間',
      action: 'Action',
      data: '數據'
    }
  },
  account: {
    created: '創建於',
    resources: '資產',
    available: '可用餘額',
    refunding: '贖回中',
    tokens: '持有代幣',
    transactions: '交易記錄',
    detail: '賬戶詳情',
    name: '名稱',
    createdTime: '創建時間',
    mainTokenBalance: '主幣種餘額',
    totalAsset: '總價值',
    owner: 'Owner 公鑰',
    active: 'Active 公鑰'
  },
  404: {
    message: '請檢查您輸入的網址是否正確，請點擊以下按鈕返回主頁或者發送錯誤報告',
    goHome: '返回首頁'
  },
  error: {
    message: 'MEPAY Explorer 支持搜索區塊高度、區塊Hash、賬號、交易等鏈上信息，建議您確認輸入內容是否正確後，嘗試重新搜索。'
  },
  tokens: {
    tokensTitle: 'Token生態',
    update: '更新於',
    totalToken: '當前Token數量',
    searchToken: '搜索Token',
    name: '代幣名稱',
    issuerName: '發行主體',
    supply: '流通量',
    maxSupply: '發行總量',
    toDetail: '查看',
    tokenDetails: 'Token詳情',
    transactions: '交易記錄',
    tokenRank: 'Token 排名',
    rank: '排名',
    account: '用戶名',
    balance: '餘額',
    percentage: '佔比',
    tokenAccountsTotal: '當前持有代幣賬戶數量',
    tokenAccountPercentageTitle: '持有代幣賬戶分佈'
  },
  contracts: {
    name: '合約名字',
    totalActiveAccountCount: '24h 活躍賬戶數',
    totalCallCount: '24h 調用次數',
    actions: '合約方法',
    lastUpdateTime: '最新部署日期',
    ycxjyzt: '一次性校驗狀態',
    dmsjzt: '代碼審計狀態'
  },
  actions: {
    issue: {
      operate: '系統派發了',
      to: '給',
      dailyissue: '每日發行'
    },
    activate: {
      operate: '邀請賬戶'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'MEPAY是一個分佈式應用程序，將會選出區塊生產者，為社區提供快速穩定的交易環境，塊生產者將接入高端配置的分佈服務器，給網絡提供正常的運行，並且在MEPAY上您可以編寫代碼來運行自己的智能合約！'
  },
  newfooter: {
    title1: 'MEPAY社區',
    title2: '技術支持',
    title3: '關於我們',
    text1: '區塊鏈源碼',
    text2: '錢包下載',
    text3: '支持幫助：',
    text4: '商務合作：'
  },
  chaoji: {
    title1: '编号',
    title2: '节点区域',
    title3: '节点ID',
    title4: '节点名称',
    title5: '区块高度',
    title6: '最新区块Hash',
    zanwu: '暂无数据'
  },
  zizhanghao: {
    text1: '子賬號',
    text2: '創建時間'
  }
}
