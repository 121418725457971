export default {
  common: {
    copy: '复制',
    copySuccessMessage: '复制成功',
    copyFailedMessage: '该浏览器不支持自动复制'
  },
  title: {
    homePage: 'MEPAY Explorer - Home Page',
    tokens: 'Token ecology',
    tokenDetail: 'Token details',
    boxredirect: 'Smart Contract Homepage',
    contractsAll:'Smart contracts',
    contractsOther:'Other contracts',
    blockList:'Block information',
    blockDetail:'Block details',
    transactionDetail:'Transaction details',
    account:'Account details',
    error:'No content',
    404:'404',
  },
  navbar: {
    homePage: 'Trang Chủ',
    contracts: 'Hợp đồng thông minh',
    tokens: 'Thông tin mã thông báo',
    blocks: 'Thông tin khối',
    generateKey: '密钥生成'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: 'Hỗ trợ kỹ thuật',
    lab: 'xxx技术实验室',
    explorer: '区块链浏览器',
    wallet: 'Tải xuống ví',
    help: '帮助中心',
    about: 'Về chúng ta',
    group: 'xxx集团',
    support: 'Ủng hộ',
    cooperation: 'Hợp tác kinh doanh',
    community: 'xxx社区'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'
  },
  search: {
    inputPlaceholder: 'Tìm kiếm/ chặn/ tài khoản/ Trx Hash',
    errorMessage: '错误：API错误：未知块：未知交易：未知帐户'
  },
  main: {
    producer: 'Nhà sản xuất khối',
    headBlock: 'Chiều cao khối',
    tps: 'Hiện tại/cao nhất TPS',
    txs: 'Số lượng giao dịch',
    transactions: 'Bản ghi giao dịch mới nhất',
    producers: 'Siêu nút',
    applyJoin: 'Đăng ký tham gia siêu nút',
    rank: 'thông báo',
    nodeArea: 'quốc gia/khu vực',
    votes: 'Tỷ lệ cân nặng/phiếu bầu',
    nodeName: 'Tên',
    blockId: 'Khối mới nhất',
    applyJoinErrorMessage: '该功能暂未开放，敬请期待'
  },
  blocks: {
    blocks: 'Danh sách chặn',
    block: 'Khối',
    blockId: 'KhốiHash',
    txs: 'Số lượng giao dịch',
    actions: 'Toán hạng',
    producer: 'Máy phát điện',
    produceTime: 'Thời gian xây dựng'
  },
  blockDetail: {
    transactions: 'Ghi lại giao dịch'
  },
  transactionDetail: {
    detail: 'Số lượng',
    actions: 'Actions',
    raw: 'dữ liệu thô',
    txId: 'Số lượngHash',
    blockId: 'KhốiHash',
    prevBlockId: 'Khối trướcHash',
    headBlock: 'Khu vực',
    producer: 'Nhà sản xuất khối',
    createTime: 'Dấu thời gian',
    action: 'Hợp đồng',
    from: 'Truyền',
    to: 'Nhận',
    quantity: 'Số',
    memo: 'nhận'
  },
  detail: {
    block: {
      blockDetail: 'Chi tiết khối',
      blockId: 'KhốiHash',
      prevBlockId: 'Khối trướcHash',
      headBlock: 'Chiều cao khối',
      txs: 'Số lượng bản ghi',
      producer: 'Nhà sản xuất khối',
      createTime: 'Dấu thời gian'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: 'thời gian',
      action: 'Action',
      data: 'dữ liệu'
    }
  },
  account: {
    created: '创建于',
    resources: 'tài sản',
    available: 'Số dư khả dụng',
    refunding: 'chuộc lỗi',
    tokens: 'Nắm giữ mã thông báo',
    transactions: 'Ghi lại giao dịch',
    detail: 'Chi tiết tài khoản',
    name: 'Tên',
    createdTime: 'Thời gian sáng tạo',
    mainTokenBalance: 'Số dư tiền tệ chính',
    totalAsset: '总价值',
    owner: 'Owner Khóa công khai',
    active: 'Active Khóa công khai'
  },
  404: {
    message: '请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告',
    goHome: '返回首页'
  },
  error: {
    message: 'NGK Explorer 支持搜索区块高度、区块Hash、账号、交易等链上信息，建议您确认输入内容是否正确后，尝试重新搜索。'
  },
  tokens: {
    tokensTitle: 'Sinh thái họcToken',
    update: 'Cập nhật vào',
    totalToken: 'Số lượng mã thông báo hiện tại',
    searchToken: 'Tìm kiếm',
    name: 'TokenTên',
    issuerName: 'Người phát hành',
    supply: 'Vòng tuần hoàn',
    maxSupply: 'Tổng số phát hành',
    toDetail: 'Lượt xem',
    tokenDetails: 'Chi tiết mã thông báo',
    transactions: 'Ghi lại giao dịch',
    tokenRank: 'Xếp hạng mã thông báo',
    rank: 'thông báo',
    account: 'tên tài khoản',
    balance: 'Thăng bằng',
    percentage: 'Phần trăm',
    tokenAccountsTotal: 'Số lượng tài khoản token hiện tại',
    tokenAccountPercentageTitle: 'Phân phối tài khoản nắm giữ mã thông báo'
  },
  contracts: {
    name: 'Tên hợp đồng',
    totalActiveAccountCount: '24h Số lượng tài khoản đang hoạt động',
    totalCallCount: '24h Số lượng cuộc gọi',
    actions: '合约方法',
    lastUpdateTime: 'Ngày triển khai mới nhất',
    ycxjyzt: 'Trạng thái xác minh một lần',
    dmsjzt: 'Trạng thái kiểm tra mã'
  },
  actions: {
    issue: {
      operate: '系统派发了',
      to: '给',
      dailyissue: '每日发行'
    },
    activate: {
      operate: '邀请账户'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'MEPAY là một chương trình ứng dụng phân tán. Các nhà sản xuất khối sẽ được chọn để cung cấp môi trường giao dịch nhanh và ổn định cho cộng đồng. Các nhà sản xuất khối sẽ được kết nối với các máy chủ phân tán cấu hình cao để cung cấp hoạt động bình thường của mạng và bạn sẽ ở trên MEPAY. Bạn có thể viết mã để chạy hợp đồng thông minh của riêng mình'
  },
  newfooter: {
    title1: 'MEPAY cộng đồng',
    title2: 'Hỗ trợ kỹ thuật',
    title3: 'về chúng tôi',
    text1: 'Mã nguồn chuỗi khối',
    text2: 'Tải xuống ví',
    text3: 'Ủng hộ',
    text4: 'Hợp tác kinh doanh'
  },
  chaoji: {
    title1: '编号',
    title2: '节点区域',
    title3: '节点ID',
    title4: '节点名称',
    title5: '区块高度',
    title6: '最新区块Hash',
    zanwu: '暂无数据'
  },
  yiyuan: {
    title1: '愿景',
    text1: '客户第一安全第一',
    title2: '使命',
    title3: '价值观'
  },
  zizhanghao: {
    text1: 'Tài khoản phụ',
    text2: 'Thời gian sáng tạo'
  }
}
