export default {
    common: {
        copy: 'نسخ',
        copySuccessMessage: 'تم النسخ بنجاح',
        copyFailedMessage: 'المتصفح لا يدعم النسخ التلقائي'
    },
    title: {
        homePage: 'MEPAY Explorer - Home Page',
        tokens: 'Token ecology',
        tokenDetail: 'Token details',
        boxredirect: 'Smart Contract Homepage',
        contractsAll:'Smart contracts',
        contractsOther:'Other contracts',
        blockList:'Block information',
        blockDetail:'Block details',
        transactionDetail:'Transaction details',
        account:'Account details',
        error:'No content',
        404:'404',
    },
    navbar: {
        homePage: 'مسكن',
        contracts: 'عقد ذكي',
        tokens: 'معلومات الرمز',
        blocks: 'معلومات كتلة',
        generateKey: 'توليد مفتاح'
    },
    footer: {
        ecological: 'xxx',
        ex: 'xxx',
        game: 'xxx',
        sto: 'xxx',
        bank: 'xxx',
        capital: 'xxx',
        techSupport: 'دعم فني',
        lab: 'المختبر الفني الثلاثون',
        explorer: 'متصفح Blockchain',
        wallet: 'تنزيل المحفظة',
        help: 'مركز المساعدة',
        about: 'معلومات عنا',
        group: 'مجموعة الثلاثون',
        support: 'مساعدة الدعم',
        cooperation: 'التعاون التجاري',
        community: 'مجتمع xxx'
    },
    lang: {
        en: 'English',
        cn: '简体中文',
        ko: '한국어',
        tw: '中文繁体',
        ja: '日本語',
        pt: 'Português',
        ru: 'русский',
        es: 'Español',
        fr: 'français',
        de: 'Deutsche',
        ar: 'عربى'
    },
    search: {
        inputPlaceholder: 'بحث كتلة / حساب / Trx Hash',
        errorMessage: 'خطأ: خطأ API: حظر غير معروف: معاملة غير معروفة: حساب غير معروف'
    },
    main: {
        producer: 'عقدة كتلة',
        headBlock: 'ارتفاع كتلة',
        tps: 'الحالية/أعلى TPS',
        txs: 'عدد الصفقات',
        transactions: 'آخر تاريخ المعاملة',
        producers: 'Supernode',
        applyJoin: 'تقديم طلب للانضمام العقدة السوبر',
        rank: 'مرتبة',
        nodeArea: 'البلد / المنطقة',
        votes: 'وزن / معدل الأصوات',
        nodeName: 'اسم',
        blockId: 'أحدث كتلة تجزئة',
        applyJoinErrorMessage: 'هذه الميزة لم تكن مفتوحة، حتى ضبطها البقاء'
    },
    blocks: {
        blocks: 'قائمة الحظر',
        block: 'منع',
        blockId: 'كتلة تجزئة',
        txs: 'عدد الصفقات',
        actions: 'معامل',
        producer: 'مولد',
        produceTime: 'وقت الجيل'
    },
    blockDetail: {
        transactions: 'تفاصيل الصفقه'
    },
    transactionDetail: {
        detail: 'تفاصيل الصفقه',
        actions: 'Actions',
        raw: 'البيانات الخام',
        txId: 'تجزئة التداول',
        blockId: 'كتلة تجزئة',
        prevBlockId: 'تجزئة الكتلة السابقة',
        headBlock: 'منع',
        producer: 'عقدة كتلة',
        createTime: 'الطابع الزمني',
        action: 'طريقة العقد',
        from: 'المحول',
        to: 'المتلقي',
        quantity: 'كمية',
        memo: 'ملاحظات'
    },
    detail: {
        block: {
            blockDetail: 'منع التفاصيل',
            blockId: 'كتلة التجزئة',
            prevBlockId: 'تجزئة الكتلة السابقة',
            headBlock: 'ارتفاع كتلة',
            txs: 'عدد السجلات',
            producer: 'عقدة كتلة',
            createTime: 'الطابع الزمني'
        },
        transaction: {
            trxId: 'Trx Hash',
            time: 'زمن',
            action: 'Action',
            data: 'بيانات'
        }
    },
    account: {
        created: 'مدمج',
        resources: 'الأصول',
        available: 'الرصيد المتاح',
        refunding: 'التعويض',
        tokens: 'عقد الرموز',
        transactions: 'سجل المعاملات',
        detail: 'تفاصيل الحساب',
        name: 'اسم',
        createdTime: 'وقت إنشاء',
        mainTokenBalance: 'رصيد العملة الرئيسي',
        totalAsset: 'قيمه كليه',
        owner: 'Owner مفتاح عام',
        active: 'Active مفتاح عام'
    },
    404: {
        message: 'يرجى التحقق مما إذا كان عنوان URL الذي أدخلته صحيحًا ، الرجاء النقر فوق الزر أدناه للعودة إلى الصفحة الرئيسية أو إرسال تقرير عن الخطأ',
        goHome: 'عد إلى الأعلى'
    },
    error: {
        message: 'يدعم NGK Explorer البحث عن معلومات حول السلسلة مثل ارتفاع الكتلة وتجزئة الكتلة ورقم الحساب والمعاملات. يوصى بمحاولة البحث مرة أخرى بعد التأكد من صحة محتوى الإدخال.'
    },
    tokens: {
        tokensTitle: 'Tokenعلم البيئة',
        update: 'تحديث في',
        totalToken: 'العدد الحالي من الرموز',
        searchToken: 'بحث عنToken',
        name: 'Tokenاسم',
        issuerName: 'المصدر',
        supply: 'السيولة',
        maxSupply: 'إصدار الكلي',
        toDetail: 'رأي',
        tokenDetails: 'Tokenتفاصيل',
        transactions: 'سجل المعاملات',
        tokenRank: 'Token مرتبة',
        rank: 'مرتبة',
        account: 'اسم المستخدم',
        balance: 'توازن',
        percentage: 'نسبة',
        tokenAccountsTotal: 'العدد الحالي للحسابات عقد رمزية',
        tokenAccountPercentageTitle: 'توزيع حسابات عقد رمزية'
    },
    contracts: {
        name: 'اسم العقد',
        totalActiveAccountCount: '24h حسابات نشطة',
        totalCallCount: '24h المكالمات',
        actions: 'طريقة العقد',
        lastUpdateTime: 'آخر تاريخ نشر',
        ycxjyzt: 'حالة الاختيار لمرة واحدة',
        dmsjzt: 'حالة تدقيق الرمز'
    },
    actions: {
        issue: {
            operate: 'توزيع النظام',
            to: 'يعطى',
            dailyissue: 'العدد اليومي'
        },
        activate: {
            operate: 'حساب الدعوة'
        }
    },
    banner: {
        title: 'MEPAY ',
        titleen: 'Blockchain Explorer',
        text: 'NGK.IO هو تطبيق موزع. سيتم اختيار منتجي البلوك لتوفير بيئة تداول سريعة ومستقرة للمجتمع. وسيصل منتجو البلوك إلى خوادم موزعة عالية الجودة لتوفير التشغيل العادي للشبكة. يمكنك كتابة الرمز على .IO لتشغيل العقد الذكي الخاص بك!'
    },
    newfooter: {
        title1: 'مجتمع NGK',
        title2: 'دعم فني',
        title3: 'معلومات عنا',
        text1: 'رمز مصدر بلوكشين',
        text2: 'تنزيل المحفظة',
        text3: 'مساعدة الدعم:',
        text4: 'التعاون التجاري:'
    },
    chaoji: {
        title1: '编号',
        title2: '节点区域',
        title3: '节点ID',
        title4: '节点名称',
        title5: '区块高度',
        title6: '最新区块Hash',
        zanwu: '暂无数据'
    }
}

