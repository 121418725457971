export default {
  common: {
    copy: '부',
    copySuccessMessage: '복사 성공',
    copyFailedMessage: '브라우저는 자동 복사를 지원하지 않습니다'
  },
  title: {
    homePage: 'MEPAY Explorer - Home Page',
    tokens: 'Token ecology',
    tokenDetail: 'Token details',
    boxredirect: 'Smart Contract Homepage',
    contractsAll:'Smart contracts',
    contractsOther:'Other contracts',
    blockList:'Block information',
    blockDetail:'Block details',
    transactionDetail:'Transaction details',
    account:'Account details',
    error:'No content',
    404:'404',
  },
  navbar: {
    homePage: '집',
    contracts: '스마트 계약',
    tokens: '토큰 정보',
    blocks: '정보 차단',
    generateKey: '키 생성'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: '기술적 지원',
    lab: 'xxx 기술 연구소',
    explorer: 'Blockchain 브라우저',
    wallet: '월렛 다운로드',
    help: '지원 센터',
    about: '우리에 대해',
    group: 'xxx 그룹',
    support: '지원 도움말',
    cooperation: '사업 협력',
    community: 'xxx 커뮤니티'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'
  },
  search: {
    inputPlaceholder: '검색 / 차단 / 계정/ Trx 해시',
    errorMessage: '오류 : API 오류 : 알 수없는 차단 : 알 수없는 거래 : 알 수없는 계정'
  },
  main: {
    producer: '블록 프로듀서',
    headBlock: '블록 높이',
    tps: '현재 / 최고 TPS',
    txs: '거래 수',
    transactions: '최근 거래 기록',
    producers: '슈퍼 노드',
    applyJoin: '수퍼 노드 가입 신청',
    rank: '계급',
    nodeArea: '국가/지역',
    votes: '무게 / 투표율',
    nodeName: '이름',
    blockId: '최신 블록Hash',
    applyJoinErrorMessage: '숙박 조정 있도록이 기능은 아직 열려 있지'
  },
  blocks: {
    blocks: '차단리스트',
    block: '블록',
    blockId: '블록Hash',
    txs: '거래 수',
    actions: '피연산자',
    producer: '발전기',
    produceTime: '생성 시간'
  },
  blockDetail: {
    transactions: '거래 기록'
  },
  transactionDetail: {
    detail: '상세 거래 내역',
    actions: 'Actions',
    raw: '원시 데이터',
    txId: '거래Hash',
    blockId: '블록Hash',
    prevBlockId: '이전 블록Hash',
    headBlock: '블록',
    producer: '블록 노드',
    createTime: '타임 스탬프',
    action: '계약 방법',
    from: '양도자',
    to: '리시버',
    quantity: '수량',
    memo: '비고'
  },
  detail: {
    block: {
      blockDetail: '블록 세부 사항',
      blockId: '블록Hash',
      prevBlockId: '이전 블록Hash',
      headBlock: '블록 높이',
      txs: '레코드 수',
      producer: '블록 노드',
      createTime: '타임 스탬프'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: '시각',
      action: 'Action',
      data: '데이터'
    }
  },
  account: {
    created: '내장',
    resources: '자산',
    available: '사용 가능한 잔액',
    refunding: '상환',
    tokens: '보유 토큰',
    transactions: '거래 기록',
    detail: '계정 정보',
    name: '이름',
    createdTime: '제작 시간',
    mainTokenBalance: '주요 통화 잔액',
    totalAsset: '총 가치',
    owner: 'Owner 공개 키',
    active: 'Active 공개 키'
  },
  404: {
    message: '입력 한 URL이 올바른지 확인하십시오. 홈페이지로 돌아가거나 오류 보고서를 보내려면 아래 버튼을 클릭하십시오',
    goHome: '맨 위로'
  },
  error: {
    message: 'MEPAY Explorer는 블록 높이, 블록 해시, 계정 번호 및 트랜잭션과 같은 체인 정보 검색을 지원하므로 입력 내용이 올바른지 확인한 후 다시 검색하는 것이 좋습니다.'
  },
  tokens: {
    tokensTitle: 'Token생태학',
    update: '에 업데이트',
    totalToken: '흐름Token수량',
    searchToken: '검색Token',
    name: 'Token이름',
    issuerName: '발급자',
    supply: '유동성',
    maxSupply: '총 발행',
    toDetail: '전망',
    tokenDetails: 'Token세부',
    transactions: '거래 기록',
    tokenRank: 'Token 계급',
    rank: '계급',
    account: '사용자 이름',
    balance: '밸런스',
    percentage: '비율',
    tokenAccountsTotal: '현재 토큰 보유 계좌 수',
    tokenAccountPercentageTitle: '토큰 보유 계좌 분배'
  },
  contracts: {
    name: '계약 명',
    totalActiveAccountCount: '24h 활성 계정',
    totalCallCount: '24h 전화',
    actions: '계약 방법',
    lastUpdateTime: '최신 배포 날짜',
    ycxjyzt: '일회성 확인 상태',
    dmsjzt: '코드 감사 상태'
  },
  actions: {
    issue: {
      operate: '분산 된 시스템',
      to: '주기',
      dailyissue: '일일 호'
    },
    activate: {
      operate: '초대 계정'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'MEPAY는 분산 응용 프로그램으로, 커뮤니티에 빠르고 안정적인 거래 환경을 제공하기 위해 블록 생산자를 선발합니다. 블록 생산자는 네트워크의 정상적인 운영을 제공하기 위해 유명 분산 서버에 연결되어 MEPAY에있게됩니다. 자신의 스마트 계약을 실행하는 코드를 작성할 수 있습니다.'
  },
  newfooter: {
    title1: 'MEPAY 커뮤니티',
    title2: '기술적 지원',
    title3: '우리에 대해',
    text1: '블록 체인 소스 코드',
    text2: '월렛 다운로드',
    text3: '지원 도움말 :',
    text4: '사업 협력 :'
  },
  chaoji: {
    title1: '编号',
    title2: '节点区域',
    title3: '节点ID',
    title4: '节点名称',
    title5: '区块高度',
    title6: '最新区块Hash',
    zanwu: '暂无数据'
  },
  zizhanghao: {
    text1: '서브 계정',
    text2: '생성 시간'
  }
}
