export default {
    common: {
        copy: 'Copiar',
        copySuccessMessage: 'Copia exitosa',
        copyFailedMessage: 'El navegador no admite la copia automática.'
    },
    title: {
        homePage: 'MEPAY Explorer - Home Page',
        tokens: 'Token ecology',
        tokenDetail: 'Token details',
        boxredirect: 'Smart Contract Homepage',
        contractsAll:'Smart contracts',
        contractsOther:'Other contracts',
        blockList:'Block information',
        blockDetail:'Block details',
        transactionDetail:'Transaction details',
        account:'Account details',
        error:'No content',
        404:'404',
    },
    navbar: {
        homePage: 'Hogar',
        contracts: 'Contrato inteligente',
        tokens: 'Información de token',
        blocks: 'Bloquear información',
        generateKey: 'Generación clave'
    },
    footer: {
        ecological: 'xxx',
        ex: 'xxx',
        game: 'xxx',
        sto: 'xxx',
        bank: 'xxx',
        capital: 'xxx',
        techSupport: 'Soporte técnico',
        lab: 'xxxlaboratorio técnico',
        explorer: 'Navegador blockchain',
        wallet: 'Monedero descarga',
        help: 'Centro de ayuda',
        about: 'sobre nosotros',
        group: 'grupo xxx',
        support: 'Ayuda de apoyo',
        cooperation: 'Cooperación empresarial',
        community: 'comunidad xxx'
    },
    lang: {
        en: 'English',
        cn: '简体中文',
        ko: '한국어',
        tw: '中文繁体',
        ja: '日本語',
        pt: 'Português',
        ru: 'русский',
        es: 'Español',
        fr: 'français',
        de: 'Deutsche',
        ar: 'عربى'
    },
    search: {
        inputPlaceholder: '搜索 区块 / 账户 / Trx HashBloque de búsqueda / Cuenta / Trx Hash',
        errorMessage: 'Error: Error de API: Bloque desconocido: Transacción desconocida: Cuenta desconocida'
    },
    main: {
        producer: 'Nodo de bloque',
        headBlock: 'Altura del bloque',
        tps: 'TPS actual/más alto',
        txs: 'Numero de transacciones',
        transactions: 'Último historial de transacciones',
        producers: 'Supernodo',
        applyJoin: 'Solicite unirse al súper nodo',
        rank: 'Rango',
        nodeArea: 'país/región',
        votes: 'Peso/Tasa de votos',
        nodeName: '名称',
        blockId: 'Último bloque Hash',
        applyJoinErrorMessage: 'Esta característica aún no está abierta, así que estad atentos'
    },
    blocks: {
        blocks: 'Lista de bloqueo',
        block: 'Bloquear',
        blockId: 'Block Hash',
        txs: 'Número de transacciones',
        actions: 'Operando',
        producer: 'Generador',
        produceTime: 'Tiempo generacional'
    },
    blockDetail: {
        transactions: 'Registro de transacciones'
    },
    transactionDetail: {
        detail: 'Detalles de transacción',
        actions: 'Actions',
        raw: 'Datos sin procesar',
        txId: 'Trading Hash',
        blockId: 'Block Hash',
        prevBlockId: 'Bloque anterior Hash',
        headBlock: 'Bloquear',
        producer: 'Nodo de bloque',
        createTime: 'Marca de tiempo',
        action: 'Método de contrato',
        from: 'Transferidor',
        to: 'Receptor',
        quantity: 'Cantidad',
        memo: 'Observaciones'
    },
    detail: {
        block: {
            blockDetail: 'bloque detalles',
            blockId: 'Block Hash',
            prevBlockId: 'Bloque anterior Hash',
            headBlock: 'Altura del bloque',
            txs: 'Numero de registros',
            producer: 'Nodo de bloque',
            createTime: 'Marca de tiempo'
        },
        transaction: {
            trxId: 'Trx Hash',
            time: 'hora',
            action: 'Action',
            data: 'datos'
        }
    },
    account: {
        created: 'Construido en',
        resources: 'bienes',
        available: 'Saldo disponible',
        refunding: 'Redentor',
        tokens: 'Sosteniendo fichas',
        transactions: 'Registro de la transacción',
        detail: 'Detalles de la cuenta',
        name: 'nombre',
        createdTime: 'hora de creación',
        mainTokenBalance: 'Saldo monetario principal',
        totalAsset: 'valor total',
        owner: 'Owner Llave pública',
        active: 'Active Llave pública'
    },
    404: {
        message: 'Verifique si la URL que ingresó es correcta, haga clic en el botón a continuación para regresar a la página de inicio o enviar un informe de error',
        goHome: 'Volver arriba'
    },
    error: {
        message: 'NGK Explorer admite la búsqueda de información en la cadena, como la altura del bloque, el hash del bloque, el número de cuenta, la transacción, etc. Se recomienda que intente buscar nuevamente después de confirmar si el contenido de entrada es correcto'
    },
    tokens: {
        tokensTitle: 'Token Ecología',
        update: 'Actualizado en',
        totalToken: 'Número actual de tokens',
        searchToken: 'Buscar token',
        name: 'Nombre del token',
        issuerName: 'Editor',
        supply: 'Liquidez',
        maxSupply: 'Emisión total',
        toDetail: 'Ver',
        tokenDetails: 'detalles Token',
        transactions: 'Registro de transacciones',
        tokenRank: 'Token Rango',
        rank: 'Rango',
        account: 'nombre de usuario',
        balance: 'Equilibrar',
        percentage: 'Proporción',
        tokenAccountsTotal: 'Número actual de cuentas de token',
        tokenAccountPercentageTitle: 'Distribución de cuentas de token holding'
    },
    contracts: {
        name: 'Nombre del contrato',
        totalActiveAccountCount: '24h Cuentas activas',
        totalCallCount: '24h Llamadas',
        actions: 'Método de contrato',
        lastUpdateTime: 'Última fecha de implementación',
        ycxjyzt: 'Estado de verificación única',
        dmsjzt: 'Estado de auditoría de código'
    },
    actions: {
        issue: {
            operate: 'El sistema distribuido',
            to: 'dar',
            dailyissue: 'Problema diario'
        },
        activate: {
            operate: 'Cuenta de invitación'
        }
    },
    banner: {
        title: 'MEPAY ',
        titleen: 'Blockchain Explorer',
        text: 'NGK.IO es una aplicación distribuida. Los productores de bloques serán seleccionados para proporcionar un entorno comercial rápido y estable para la comunidad. Los productores de bloques accederán a servidores distribuidos de alta gama para proporcionar operaciones normales para la red. ¡Puede escribir código en .IO para ejecutar su propio contrato inteligente!'
    },
    newfooter: {
        title1: 'Comunidad NGK',
        title2: 'Soporte técnico',
        title3: 'sobre nosotros',
        text1: 'código fuente Blockchain',
        text2: 'Descarga de billetera',
        text3: 'Ayuda de soporte:',
        text4: 'Cooperación empresarial:'
    },
    chaoji: {
        title1: '编号',
        title2: '节点区域',
        title3: '节点ID',
        title4: '节点名称',
        title5: '区块高度',
        title6: '最新区块Hash',
        zanwu: '暂无数据'
    }
}

