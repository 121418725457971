export default {
  common: {
    copy: '复制',
    copySuccessMessage: '复制成功',
    copyFailedMessage: '该浏览器不支持自动复制'
  },
  title: {
    homePage: 'MEPAY Explorer - 首页',
    tokens: 'Token生态',
    tokenDetail: 'Token详情',
    boxredirect: '智能合约首页',
    contractsAll:'智能合约',
    contractsOther:'其它合约',
    blockList:'区块信息',
    blockDetail:'区块详情',
    transactionDetail:'交易详情',
    account:'账号详情',
    error:'无内容',
    404:'404',
  },
  navbar: {
    homePage: '首页',
    contracts: '智能合约',
    tokens: '代币信息',
    blocks: '区块信息',
    generateKey: '密钥生成'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: '技术支持',
    lab: 'xxx技术实验室',
    explorer: '区块链浏览器',
    wallet: '钱包下载',
    help: '帮助中心',
    about: '关于我们',
    group: 'xxx集团',
    support: '支持帮助',
    cooperation: '商务合作',
    community: 'xxx社区'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'

  },
  search: {
    inputPlaceholder: '搜索 区块 / 账户 / Trx Hash',
    errorMessage: '错误：API错误：未知块：未知交易：未知帐户'
  },
  main: {
    producer: '出块节点',
    headBlock: '区块高度',
    tps: '当前/最高TPS',
    txs: '交易数量',
    transactions: '最新交易记录',
    producers: '超级节点',
    applyJoin: '申请加入超级节点',
    rank: '排名',
    nodeArea: '国家地区',
    votes: '权重 / 得票率',
    nodeName: '名称',
    blockId: '最新区块Hash',
    applyJoinErrorMessage: '该功能暂未开放，敬请期待'
  },
  blocks: {
    blocks: '区块列表',
    block: '区块',
    blockId: '区块Hash',
    txs: '交易数',
    actions: '操作数',
    producer: '生成者',
    produceTime: '生成时间'
  },
  blockDetail: {
    transactions: '交易记录'
  },
  transactionDetail: {
    detail: '交易详情',
    actions: 'Actions',
    raw: '原始数据',
    txId: '交易Hash',
    blockId: '区块Hash',
    prevBlockId: '上一区块Hash',
    headBlock: '所在区块',
    producer: '出块节点',
    createTime: '时间戳',
    action: '合约方法',
    from: '转账人',
    to: '接收人',
    quantity: '数量',
    memo: '备注'
  },
  detail: {
    block: {
      blockDetail: '区块详情',
      blockId: '区块Hash',
      prevBlockId: '上一区块Hash',
      headBlock: '区块高度',
      txs: '记录数量',
      producer: '出块节点',
      createTime: '时间戳'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: '时间',
      action: 'Action',
      data: '数据'
    }
  },
  account: {
    created: '创建于',
    resources: '资产',
    available: '可用余额',
    refunding: '赎回中',
    tokens: '持有代币',
    transactions: '交易记录',
    detail: '账户详情',
    name: '名称',
    createdTime: '创建时间',
    mainTokenBalance: '主币种余额',
    totalAsset: '总价值',
    owner: 'Owner 公钥',
    active: 'Active 公钥'
  },
  404: {
    message: '请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告',
    goHome: '返回首页'
  },
  error: {
    message: 'NGK Explorer 支持搜索区块高度、区块Hash、账号、交易等链上信息，建议您确认输入内容是否正确后，尝试重新搜索。'
  },
  tokens: {
    tokensTitle: 'Token生态',
    update: '更新于',
    totalToken: '当前Token数量',
    searchToken: '搜索Token',
    name: 'Token名称',
    issuerName: '发行主体',
    supply: '流通量',
    maxSupply: '发行总量',
    toDetail: '查看',
    tokenDetails: 'Token详情',
    transactions: '交易记录',
    tokenRank: 'Token 排名',
    rank: '排名',
    account: '用户名',
    balance: '余额',
    percentage: '占比',
    tokenAccountsTotal: '当前持有代币账户数量',
    tokenAccountPercentageTitle: '持有代币账户分布'
  },
  contracts: {
    name: '合约名字',
    totalActiveAccountCount: '24h 活跃账户数',
    totalCallCount: '24h 调用次数',
    actions: '合约方法',
    lastUpdateTime: '最新部署日期',
    ycxjyzt: '一次性校验状态',
    dmsjzt: '代码审计状态'
  },
  actions: {
    issue: {
      operate: '系统派发了',
      to: '给',
      dailyissue: '每日发行'
    },
    activate: {
      operate: '邀请账户'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'MEPAY是一个分布式应用程序，将会选出区块生产者，为社区提供快速稳定的交易环境，区块生产者将接入高配的分布服务器，给网络提供正常的运行，并且在MEPAY上您可以编写代码来运行自己的智能合约'
  },
  newfooter: {
    title1: 'MEPAY 社区',
    title2: '技术支持',
    title3: '关于我们',
    text1: '区块链源码',
    text2: '钱包下载',
    text3: '支持帮助：',
    text4: '商务合作：'
  },
  chaoji: {
    title1: '编号',
    title2: '节点区域',
    title3: '节点ID',
    title4: '节点名称',
    title5: '区块高度',
    title6: '最新区块Hash',
    zanwu: '暂无数据'
  },
  yiyuan: {
    title1: '愿景',
    text1: '客户第一安全第一',
    title2: '使命',
    title3: '价值观'
  },
  zizhanghao: {
    text1: '子账号',
    text2: '创建时间'
  }
}
