export default {
    common: {
        copy: 'Kopieren',
        copySuccessMessage: 'Kopieren erfolgreich',
        copyFailedMessage: 'Der Browser unterstützt kein automatisches Kopieren'
    },
    title: {
        homePage: 'MEPAY Explorer - Home Page',
        tokens: 'Token ecology',
        tokenDetail: 'Token details',
        boxredirect: 'Smart Contract Homepage',
        contractsAll:'Smart contracts',
        contractsOther:'Other contracts',
        blockList:'Block information',
        blockDetail:'Block details',
        transactionDetail:'Transaction details',
        account:'Account details',
        error:'No content',
        404:'404',
    },
    navbar: {
        homePage: 'Zuhause',
        contracts: 'Smart-Vertrag',
        tokens: 'Token Informationen',
        blocks: 'Blockinformation',
        generateKey: 'Schlüsselgenerierung'
    },
    footer: {
        ecological: 'xxx',
        ex: 'xxx',
        game: 'xxx',
        sto: 'xxx',
        bank: 'xxx',
        capital: 'xxx',
        techSupport: 'Technischer Support',
        lab: 'xxx technisches Labor',
        explorer: 'Blockchain-Browser',
        wallet: 'Brieftasche herunterladen',
        help: 'Hilfezentrum',
        about: 'Über uns',
        group: 'xxx Gruppe',
        support: 'Support-Hilfe',
        cooperation: 'Business Cooperation',
        community: 'xxx Gemeinschaft'
    },
    lang: {
        en: 'English',
        cn: '简体中文',
        ko: '한국어',
        tw: '中文繁体',
        ja: '日本語',
        pt: 'Português',
        ru: 'русский',
        es: 'Español',
        fr: 'français',
        de: 'Deutsche',
        ar: 'عربى'
    },
    search: {
        inputPlaceholder: 'Suchblock / Konto / Trx Hash',
        errorMessage: 'Fehler: API-Fehler: Unbekannt Block: Unbekannt Transaktion: Unbekanntes Konto'
    },
    main: {
        producer: 'Blockknoten',
        headBlock: 'Blockhöhe',
        tps: 'Aktuelle/höchste TPS',
        txs: 'Anzahl der Transaktionen',
        transactions: 'Letzte Transaktionshistorie',
        producers: 'Supernode',
        applyJoin: 'Wenden Sie den Superknoten verbinden',
        rank: 'Rang',
        nodeArea: 'Land/Region',
        votes: 'Gewicht/Stimmenzahl',
        nodeName: 'Name',
        blockId: 'Letzter Block Hash',
        applyJoinErrorMessage: 'Diese Funktion ist noch nicht geöffnet, bleiben Sie also auf dem Laufenden'
    },
    blocks: {
        blocks: 'Blockliste',
        block: 'Block',
        blockId: 'Block Hash',
        txs: 'Anzahl der Transaktionen',
        actions: 'Operand',
        producer: 'Generator',
        produceTime: 'Generationszeit'
    },
    blockDetail: {
        transactions: 'Transaktionsdatensatz'
    },
    transactionDetail: {
        detail: 'Transaktionsdetails',
        actions: 'Actions',
        raw: 'Rohdaten',
        txId: 'Handels-Hash',
        blockId: 'Block Hash',
        prevBlockId: 'Zurück Block Hash',
        headBlock: 'Block',
        producer: 'Blockknoten',
        createTime: 'Zeitstempel',
        action: 'Vertragsmethode',
        from: 'Übergeber',
        to: 'Empfänger',
        quantity: 'Menge',
        memo: 'Bemerkungen'
    },
    detail: {
        block: {
            blockDetail: 'Blockdetails',
            blockId: 'Block Hash',
            prevBlockId: 'Zurück Block Hash',
            headBlock: 'Blockhöhe',
            txs: 'Anzahl der Datensätze',
            producer: 'Blockknoten',
            createTime: 'Zeitstempel'
        },
        transaction: {
            trxId: 'Trx Hash',
            time: 'Zeit',
            action: 'Action',
            data: 'Daten'
        }
    },
    account: {
        created: 'Eingebaut',
        resources: 'Aktiva',
        available: 'Verfügbares Guthaben',
        refunding: 'Einlösen',
        tokens: 'Token halten',
        transactions: 'Transaktionsdatensatz',
        detail: 'Kontodetails',
        name: 'Name',
        createdTime: 'Erstellungszeit',
        mainTokenBalance: 'Hauptwährungssaldo',
        totalAsset: 'Gesamtwert',
        owner: 'Owner Öffentlicher Schlüssel',
        active: 'Active Öffentlicher Schlüssel'
    },
    404: {
        message: 'Bitte überprüfen Sie, ob die von Ihnen eingegebene URL korrekt ist. Klicken Sie auf die Schaltfläche unten, um zur Startseite zurückzukehren, oder senden Sie einen Fehlerbericht',
        goHome: 'Nach oben'
    },
    error: {
        message: 'NGK Explorer unterstützt die Suche nach Informationen in der Kette wie Blockhöhe, Block-Hash, Kontonummer und Transaktionen. Es wird empfohlen, erneut zu suchen, nachdem Sie überprüft haben, ob der Eingabeinhalt korrekt ist.'
    },
    tokens: {
        tokensTitle: 'Token Ökologie',
        update: 'Aktualisiert am',
        totalToken: 'Aktuelle Anzahl der Token',
        searchToken: 'Such-Token',
        name: 'Token-Name',
        issuerName: 'Aussteller',
        supply: 'Liquidität',
        maxSupply: 'Gesamtausgabe',
        toDetail: 'Aussicht',
        tokenDetails: 'Token-Details',
        transactions: 'Transaktionsdatensatz',
        tokenRank: 'Token Rang',
        rank: 'Rang',
        account: 'Nutzername',
        balance: 'Balance',
        percentage: 'Anteil',
        tokenAccountsTotal: 'Aktuelle Anzahl der Token-Konten',
        tokenAccountPercentageTitle: 'Verteilung von Token-Konten'
    },
    contracts: {
        name: 'Vertragsname',
        totalActiveAccountCount: '24h Aktive Konten',
        totalCallCount: '24h Anrufe',
        actions: 'Vertragsmethode',
        lastUpdateTime: 'Neueste Einsatz Datum',
        ycxjyzt: 'Einmaliger Prüfstatus',
        dmsjzt: 'Code-Audit-Status'
    },
    actions: {
        issue: {
            operate: 'Das System verteilt',
            to: 'geben',
            dailyissue: 'Tägliche Ausgabe'
        },
        activate: {
            operate: 'Einladungskonto'
        }
    },
    banner: {
        title: 'MEPAY ',
        titleen: 'Blockchain Explorer',
        text: 'NGK.IO ist ein verteiltes Anwendungsprogramm. Blockproduzenten werden ausgewählt, um eine schnelle und stabile Handelsumgebung für die Community bereitzustellen. Blockproduzenten greifen auf verteilte High-End-Server zu, um den normalen Betrieb des Netzwerks zu gewährleisten. Sie können Code auf .IO schreiben, um Ihren eigenen Smart-Vertrag auszuführen!'
    },
    newfooter: {
        title1: 'NGK-Community',
        title2: 'Technischer Support',
        title3: 'Über uns',
        text1: 'Blockchain-Quellcode',
        text2: 'Wallet-Download',
        text3: 'Support-Hilfe:',
        text4: 'Business Cooperation:'
    },
    chaoji: {
        title1: '编号',
        title2: '节点区域',
        title3: '节点ID',
        title4: '节点名称',
        title5: '区块高度',
        title6: '最新区块Hash',
        zanwu: '暂无数据'
    }
}

