export default {
  common: {
    copy: 'Copy',
    copySuccessMessage: 'Copy success',
    copyFailedMessage: 'The browser does not support automatic replication'
  },
  title: {
    homePage: 'MEPAY Explorer - Home Page',
    tokens: 'Token ecology',
    tokenDetail: 'Token details',
    boxredirect: 'Smart Contract Homepage',
    contractsAll:'Smart contracts',
    contractsOther:'Other contracts',
    blockList:'Block information',
    blockDetail:'Block details',
    transactionDetail:'Transaction details',
    account:'Account details',
    error:'No content',
    404:'404',
  },

  navbar: {
    homePage: 'Home',
    contracts: 'Contracts',
    tokens: 'Tokens',
    blocks: 'Blocks',
    generateKey: 'Generate Keys'
  },
  footer: {
    ecological: 'xxx',
    ex: 'xxx',
    game: 'xxx',
    sto: 'xxx',
    bank: 'xxx',
    capital: 'xxx',
    techSupport: 'Technical Support',
    lab: 'xxx Lab',
    explorer: 'Block Explorer',
    wallet: 'Wallet',
    help: 'Help Center',
    about: 'About Us',
    group: 'xxx Group',
    support: 'Support',
    cooperation: 'Business Cooperation',
    community: 'Community'
  },
  lang: {
    en: 'English',
    cn: '简体中文',
    ko: '한국어',
    tw: '中文繁体',
    ja: '日本語',
    pt: 'Português',
    ru: 'русский',
    es: 'Español',
    fr: 'français',
    de: 'Deutsche',
    ar: 'عربى',
    th: 'ไทย',
    vn: 'ViệtName',
    kh: 'ជនជាតិខ្មែរ'
  },
  search: {
    inputPlaceholder: 'Search/block/account/ Trx Hash',
    errorMessage: 'Error: API Error: Unknown Block: Unknown Transaction: Unknown Account'
  },
  main: {
    producer: 'Block out node',
    headBlock: 'Block height',
    tps: 'Current/highest TPS',
    txs: 'transaction amount',
    transactions: 'Latest transaction record',
    producers: 'Super node',
    applyJoin: 'Apply to join the super node',
    rank: 'No.',
    nodeArea: 'Area',
    votes: 'Weight/vote rate',
    nodeName: 'Name',
    blockId: 'Latest block',
    applyJoinErrorMessage: 'This function is not open yet, please stay tuned'
  },
  blocks: {
    blocks: 'Block list',
    block: 'Block',
    blockId: 'Block Hash',
    txs: 'Trxs',
    actions: 'Operand',
    producer: 'Generator',
    produceTime: 'Build time'
  },
  blockDetail: {
    transactions: 'transaction record'
  },
  transactionDetail: {
    detail: 'Trx Details',
    actions: 'Actions',
    raw: 'Raw Data',
    txId: 'Trx Hash',
    blockId: 'Block Hash',
    prevBlockId: 'Prev Block Hash',
    headBlock: 'Block',
    producer: 'Producer',
    createTime: 'Timestamp',
    action: 'Action',
    from: 'From',
    to: 'To',
    quantity: 'Quantity',
    memo: 'Memo'
  },
  detail: {
    block: {
      blockDetail: 'Block details',
      blockId: 'Block Hash',
      prevBlockId: 'Previous block',
      headBlock: 'Block Height',
      txs: 'records amount',
      producer: 'Producer',
      createTime: 'Create Time'
    },
    transaction: {
      trxId: 'Trx Hash',
      time: 'Time',
      action: 'Action',
      data: 'Data'
    }
  },
  account: {
    created: 'Created at',
    resources: 'assets',
    available: 'Available',
    refunding: 'Redemption',
    tokens: 'Holding tokens',
    transactions: 'Transactions',
    detail: 'Account Details',
    name: 'Name',
    createdTime: 'Created Time',
    mainTokenBalance: 'Available',
    totalAsset: 'Total Asset',
    owner: 'Owner Public Key',
    active: 'Active Public Key'
  },
  404: {
    message: 'Please check whether the url you entered is correct, please click the following button to return to the home page or send an error report',
    goHome: 'Return to home'
  },
  error: {
    message: 'MEPAY  Explorer supports searching information by Block/Account/Trx. Please confirm the input is correct and try searching again.'
  },
  tokens: {
    tokensTitle: 'Tokens Ecology',
    update: 'Updated',
    totalToken: 'Current Token Number',
    searchToken: 'Token',
    name: 'Name',
    issuerName: 'Issuer',
    supply: 'Circulation',
    maxSupply: 'Total issuance',
    toDetail: 'View',
    tokenDetails: 'Token details',
    transactions: 'Transactions',
    tokenRank: 'Token Rank',
    rank: 'Rank',
    account: 'user name',
    balance: 'Balance',
    percentage: 'proportion',
    tokenAccountsTotal: 'current token accounts amount',
    tokenAccountPercentageTitle: 'Token Accounts Percentage'
  },
  contracts: {
    name: 'Contract name',
    totalActiveAccountCount: 'Total Account / 24h',
    totalCallCount: 'Total Call / 24h',
    actions: 'Actions',
    lastUpdateTime: 'Last Update',
    ycxjyzt: 'One-time Check',
    dmsjzt: 'Code Audit Status'
  },
  actions: {
    issue: {
      operate: 'System issue',
      to: 'To',
      dailyissue: 'Daily issue'
    },
    activate: {
      operate: 'Invite account'
    }
  },
  banner: {
    title: 'MEPAY ',
    titleen: 'Blockchain Explorer',
    text: 'MEPAY is a distributed application program. Block producers will be selected to provide a fast and stable trading environment for the community. Block producers will be connected to high-profile distributed servers to provide normal operation of the network, that one can write code to run  their own smart contract  on MEPAY.'
  },
  newfooter: {
    title1: 'MEPAY  community',
    title2: 'Technical Support',
    title3: 'about us',
    text1: 'Blockchain source code',
    text2: 'Wallet download',
    text3: 'Support help:',
    text4: 'Business Cooperation:'
  },
  chaoji: {
    title1: 'Number',
    title2: 'Node area',
    title3: 'Node ID',
    title4: 'Node name',
    title5: 'Block height',
    title6: 'Latest block Hash',
    zanwu: 'No data'
  },
  zizhanghao: {
    text1: 'Sub account',
    text2: 'Creation time'
  }
}
